<template>
  <div class="chart-container">
    <div class="chart-header">
      <h3 class="chart-title">{{ $t("dashboard.supplier_based_reservations") }}</h3>
      <div class="chart-legend">
        <div v-for="dataset in datasets" :key="dataset.label" class="legend-item">
          <span class="legend-color" :style="{ backgroundColor: dataset.backgroundColor }"></span>
          <span class="legend-label">{{ dataset.label }}</span>
        </div>
      </div>
    </div>
    
    <Bar
      :chart-data="chartData"
      :chart-options="chartOptions"
      :height="350"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    labels: {
      type: Array,
      required: true
    },
    datasets: {
      type: Array,
      required: true
    },
    queries: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chartData: {
        labels: this.labels.map(label => label.brand),
        datasets: this.datasets.map(dataset => ({
          ...dataset,
          borderRadius: 4,
          borderWidth: 0,
          maxBarThickness: 35,
          borderSkipped: false,
        }))
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            titleColor: '#1e293b',
            bodyColor: '#475569',
            borderColor: '#e2e8f0',
            borderWidth: 1,
            padding: 12,
            usePointStyle: true,
            callbacks: {
              label: (context) => {
                return `${context.dataset.label}: ${context.formattedValue}`
              }
            }
          }
        },
        onClick: (event, elements) => {
          if (elements && elements.length > 0) {
            const { datasetIndex, index } = elements[0]
            const brandSlug = this.labels[index].brandSlug
            const statusies = this.datasets[datasetIndex].statusies
            const { startDate, endDate } = this.queries

            const url = `${process.env.VUE_APP_ADMIN_URL}reservation/index/?vendor=${brandSlug}&&status=${statusies}&&startDate=${startDate}&&endDate=${endDate}`
            window.open(url, '_blank')
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false
            },
            ticks: {
              color: '#64748b',
              font: {
                size: 12
              }
            }
          },
          y: {
            grid: {
              color: '#e2e8f0',
              drawBorder: false
            },
            ticks: {
              color: '#64748b',
              font: {
                size: 12
              },
              padding: 8,
              stepSize: 1
            },
            beginAtZero: true
          }
        }
      }
    }
  },
  watch: {
    datasets: {
      handler(newVal) {
        this.chartData.datasets = newVal.map(dataset => ({
          ...dataset,
          borderRadius: 4,
          borderWidth: 0,
          maxBarThickness: 35,
          borderSkipped: false,
        }))
      },
      deep: true
    },
    labels: {
      handler(newVal) {
        this.chartData.labels = newVal.map(label => label.brand)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  background: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
}

.chart-header {
  margin-bottom: 24px;
}

.chart-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 16px;
}

.chart-legend {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f8fafc;
  }
}

.legend-color {
  width: 12px;
  height: 12px;
  border-radius: 3px;
}

.legend-label {
  font-size: 0.875rem;
  color: #475569;
  font-weight: 500;
}

@media (max-width: 768px) {
  .chart-container {
    padding: 16px;
  }

  .chart-legend {
    gap: 12px;
  }

  .legend-item {
    padding: 3px 6px;
  }

  .legend-label {
    font-size: 0.75rem;
  }
}
</style>
