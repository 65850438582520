<template>
  <div class="donut-container">
    <div class="chart-header">
      <h3 class="chart-title">{{ $t("dashboard.reservation_status") }}</h3>
    </div>
    <Doughnut
      :chart-data="chartData"
      :chart-options="chartOptions"
      :height="300"
    />
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
)

export default {
  name: 'DonutChart',
  components: { Doughnut },
  props: {
    reservationData: {
      type: Array,
      required: true
    }
  },
  computed: {
    processedData() {
      // Toplam Gerçekleşen'i hariç tut ve diğer verileri filtrele
      const filteredData = this.reservationData.filter(item => 
        item.status.name !== 'Toplam Gerçekleşen'
      );

      return filteredData.map(item => ({
        label: item.status.name,
        value: item.length,
        color: item.status.hex || this.getStatusColor(item.status.name)
      }));
    }
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [],
          borderWidth: 0,
          borderRadius: 4,
        }]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '65%',
        layout: {
          padding: 20
        },
        plugins: {
          legend: {
            position: 'right',
            labels: {
              usePointStyle: true,
              padding: 20,
              font: {
                size: 13,
                weight: '500'
              },
              generateLabels: (chart) => {
                const data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map((label, i) => ({
                    text: `${label} (${data.datasets[0].data[i]})`,
                    fillStyle: data.datasets[0].backgroundColor[i],
                    strokeStyle: data.datasets[0].backgroundColor[i],
                    pointStyle: 'circle',
                    index: i
                  }));
                }
                return [];
              }
            }
          },
          tooltip: {
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            titleColor: '#1e293b',
            bodyColor: '#475569',
            borderColor: '#e2e8f0',
            borderWidth: 1,
            padding: 12,
            usePointStyle: true,
            callbacks: {
              label: (context) => {
                const label = context.label || '';
                const value = context.raw || 0;
                const total = context.dataset.data.reduce((a, b) => a + b, 0);
                const percentage = Math.round((value / total) * 100);
                return `${label}: ${value} (${percentage}%)`;
              }
            }
          }
        }
      }
    }
  },
  methods: {
    getStatusColor(status) {
      const colors = {
        'Gelecek': '#3b82f6',
        'Devam Eden': '#22c55e',
        'Geçmiş': '#64748b',
        'Erken İade': '#06b6d4',
        'Toplam İptal': '#ef4444'
      };
      return colors[status] || '#64748b';
    },
    updateChartData() {
      const data = this.processedData;
      this.chartData = {
        labels: data.map(item => item.label),
        datasets: [{
          data: data.map(item => item.value),
          backgroundColor: data.map(item => item.color),
          borderWidth: 0,
          borderRadius: 4,
        }]
      };
    }
  },
  watch: {
    reservationData: {
      handler() {
        this.updateChartData();
      },
      deep: true
    }
  },
  mounted() {
    this.updateChartData();
  }
}
</script>

<style lang="scss" scoped>
.donut-container {
  background: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.chart-header {
  margin-bottom: 16px;
}

.chart-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e293b;
  text-align: center;
}

@media (max-width: 768px) {
  .donut-container {
    padding: 16px;
    min-height: 350px;
  }
  
  :deep(.chartjs-render-monitor) {
    max-height: 300px !important;
  }
}
</style> 